<template>
  <div class="main">
    <div class="title" style="display: flex;align-items: center;">
      <div>考试管理</div>
      <div style="margin-left:20px;">品牌：</div>
      <el-select v-model="brandId" placeholder="请选择" @change="changebrand">
        <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id" ></el-option>
      </el-select>
    </div>
    <div class="title2">
      <div class="titlesub">
        <div class="line"></div>
        <div>层级一</div>
      </div>
      <div class="titlesub">
        <div class="line"></div>
        <div>层级二</div>
      </div>
    </div>
    <div class="content">
      <div class="tabel">
        <!-- <div class="row">
          <div>答应</div>
          <div
            style="
              display: flex;
              justify-content: center;
              width: 186px;
              border: 1px solid #e8e8e8;
              border-left: none;
            "
          >
            <el-link type="primary" :underline="false" @click="details"
              >查看详情</el-link
            >
          </div>
        </div>-->
        <div class="row" v-for="(item, index) in levelLists" :key="index">
          <div :style="{ 'border-top': index == 0 ? '' : 'none' }">{{ item.name }}</div>
          <div class="borderbottom" :style="{ 'border-top': index == 0 ? '1px solid #e8e8e8' : 'none' }">
            <el-link type="primary" :underline="false" @click="details(item)">查看详情</el-link>
          </div>
        </div>
      </div>
      <div class="tabel">
        <div class="row" v-for="(item, index) in levelLists2" :key="index">
          <div :style="{ 'border-top': index == 0 ? '' : 'none' }">{{ item.name }}</div>
          <div class="borderbottom" :style="{ 'border-top': index == 0 ? '1px solid #e8e8e8' : 'none' }">
            <el-link type="primary" :underline="false" @click="details(item)">查看详情</el-link>
          </div>
        </div>
        <!-- <div class="row">
          <div>御史</div>
          <div
            style="
              display: flex;
              justify-content: center;
              width: 186px;
              border: 1px solid #e8e8e8;
              border-left: none;
            "
          >
            <el-link type="primary" :underline="false" @click="details"
              >查看详情</el-link
            >
          </div>
        </div>-->
        <!-- <div class="row" v-for="(item, index) in list2" :key="index">
          <div style="border-top: none">{{ item }}</div>
          <div class="borderbottom">
            <el-link type="primary" :underline="false" @click="details"
              >查看详情</el-link
            >
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { levelList, levelList2 } from "../../apis/index";
export default {
  data() {
    return {
      brandList: [],
      brandId:1,
      detsilsInfo: {},
      levelLists: [],
      levelLists2: [],
      // list1: ["常在", "贵人", "嫔", "妃", "贵妃", "皇贵妃", "皇后"],
      // list2: ["尚书", "太傅", "将军", "丞相", "皇上"],
    };
  },
  
  created() {
    // this.leave()
    this.brandList = this.$store.state.brandList
    this.leave2()
  },
  methods: {
    changebrand(){
    this.leave2()
  },
    details(item) {
      let items=item
      items.brandId=this.brandId
      this.$router.push({ path: "/textSetting", query: items });
    },
    // async leave() {
    //   //获取等级列表
    //   const { data } = await levelList({ roleId: 3,brandId:this.brandId });
    //   if (data.code == 200) {
    //     this.levelLists = data.data;
    //   } else {
    //     this.$message.error(data.msg);
    //   }
    // },
    async leave2() {
      //获取等级层级列表
      const { data } = await levelList2({ roleId: 3,brandId:this.brandId });
      if (data.code == 200) {
        this.detsilsInfo = data.data
        console.log(data.data)
        this.levelLists = data.data[0].levelList
        this.levelLists2 = data.data[1].levelList
        // this.levelLists = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
  },
};
</script>

<style lang='less' scoped>
.main {
  margin: 17px 0;
  // padding:0 57px;
  background: #fff;
  .title {
    font-size: 26px;
    color: black;
    font-weight: bold;
    padding: 23px 0;
    margin-left: 57px;
  }
  .title2 {
    display: flex;
    margin: 0 57px;
  }
  .titlesub {
    display: flex;
    align-items: center;
    font-size: 20px;
    width: 50%;
    .line {
      width: 9px;
      height: 22px;
      border-radius: 6px;
      background: #409eff;
      margin-right: 11px;
    }
  }
  .content {
    margin: 23px 57px;
    padding-bottom: 118px;
    display: flex;
    .tabel {
      width: 50%;
      .row {
        display: flex;

        height: 68px;
        div:first-child {
          width: 293px;
          line-height: 68px;
          text-align: center;
          border: 1px solid #e8e8e8;
        }
      }
    }
  }
  .borderbottom {
    display: flex;
    justify-content: center;
    width: 186px;
    border: 1px solid #e8e8e8;
    border-left: none;
    border-top: none;
  }
}
</style>